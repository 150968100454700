/** Help Variables **/
/* Fonts */
/* Images */
/** Bootstrap Variables **/
/* Colors */
/* Reassign color vars to semantic color scheme */
/* Fonts */
.ds-full-screen-loader {
  height: 100vh;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}